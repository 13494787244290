"use client";
//? Library Imports ------------------------------------------------------------>
import { useState, useEffect, useMemo, useCallback } from "react";
import Cookies from "js-cookie";
import {
  Col,
  Row,
  Tag,
  Space,
  Form,
  Input,
  Button,
  message,
  Typography,
  Spin,
  Image,
} from "antd";
// import Image from "next/image";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import Link from "next/link";

//? Assets --------------------------------------------------------------------->
import { ExclamationCircleOutlined, RedoOutlined } from "@ant-design/icons";
import Bell from "../../../../../assets/images/ProductPage/Bell.svg";
import { removeCommasAndConvertToNumber } from "../../../../../functions/HelperFunctions/index";
import { HiOutlineShoppingBag } from "react-icons/hi";

//? Modules -------------------------------------------------------------------->
//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function NotifyForm({
  userAgent,
  ProductDetail,
  SelectedVariant,
  hideNotifyForm,
}) {
  //? Hooks ---------------------------------------------------------------------->
  const { isMobile, isTablet, isDesktop } = userAgent;

  //? Redux States --------------------------------------------------------------->
  // const defaultCurrency = useSelector(
  //   (state) => state?.multiLocation?.defaultCurrency
  // );
  const defaultCurrency = Cookies.get("defaultCurrencyName");

  //? States --------------------------------------------------------------------->
  const [requiredFormState, setrequiredFormState] = useState(true);
  const [notifyUserLoader, setNotifyUserLoader] = useState(false);
  const [notifyUserSuccess, setNotifyUserSuccess] = useState(false);

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phoneNo: "",
  });
  //? Functions ------------------------------------------------------------------>
  //? Life Cycles ---------------------------------------------------------------->
  const moengageNotifyMe = (data) => {
    if (window.Moengage && window.Moengage.track_event) {
      window.Moengage.track_event("notify me", {
        Email: data?.email,
        Name: data?.name,
        Source: "Website",
        Phone: data?.phone,
        // "First Session": true
      });
      window.Moengage.add_email(data?.email);
      Moengage.add_unique_user_id(data?.email);
      window.Moengage.add_first_name(data?.name);
      window.Moengage.add_last_name(data?.name);
      window.Moengage.add_user_name(data?.name);
      data?.phone && window.Moengage.add_mobile(data?.phone);
    }
  };
  const notifyUser = async (data) => {
    setNotifyUserLoader(true);
    try {
      const body = {
        name: data?.full_name,
        email: data?.email,
        phone: data?.phoneNo,
        variant: ProductDetail?.variants[0]?.variant_id,
      };
      moengageNotifyMe(body);
      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };

      const Notify = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/add_product_to_notify_by_user?storefront=23`,
        payload
      );

      if (!Notify.ok) {
        throw new Error("NotifyUser API Failed!");
      }

      // const NotifyResponse = await Notify.json();

      if (Notify?.ok) {
        // console.log("Notification Enabled Successful");
        setNotifyUserLoader(false);
        setNotifyUserSuccess(true);
        setFormData((prevState) => ({
          ...prevState,
          phoneNo: "",
          full_name: "",
          email: "",
        }));
        // message.success("Notification Enabled Successfully!");
      } else {
        // console.log("Notification Failed");
        // message.error("Notification Enabled Failed!");
        setNotifyUserLoader(false);
        setNotifyUserSuccess(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setNotifyUserLoader(false);
      setNotifyUserSuccess(false);
    }
  };

  // console.log("ProductDetail", ProductDetail);
  // console.log("SelectedVariant", SelectedVariant);
  const handleProductDiscountedPrice = () => {
    // .toFixed(2)
    {
      return removeCommasAndConvertToNumber(
        ProductDetail?.variants[0]?.variant_detail?.discounted_price
      ).toLocaleString();
    }
  };

  const handleProductOrignalPrice = () => {
    // .toFixed(2)
    {
      return removeCommasAndConvertToNumber(
        ProductDetail?.variants[0]?.variant_detail?.original_price
      ).toLocaleString();
    }
  };

  const handlePercentageOff = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      ProductDetail?.variants[0]?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      ProductDetail?.variants[0]?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return ""; // Return an empty string or handle edge cases where the percentage cannot be calculated
  };
  const Fabric = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Shirt Fabric"
      ),
    [ProductDetail?.features]
  );

  const handleFormChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (formData?.phoneNo === "" && formData?.email === "") {
      setrequiredFormState(true);
    } else {
      setrequiredFormState(false);
    }
  }, [formData]);

  return (
    <Col span={24}>
      <Col span={24}>
        <Space direction="vertical">
          <Text className={isDesktop ? "font20" : "font16"}>
            {ProductDetail?.title ? ProductDetail?.title : "N/A"}
          </Text>

          {/* //* Product SKU, Brand, Fabric -------------------------------------------------> */}

          <Col
            className={
              isMobile || isTablet
                ? "backgroundLight paddingLeft10 paddingRight10"
                : ""
            }
            flex={
              isMobile || isTablet
                ? "100%"
                : ProductDetail?.images?.length === 1
                ? "60%"
                : "96%"
            }
          >
            <Row
              className={
                isMobile || isTablet
                  ? "width100 paddingTop10 paddingBottom10"
                  : "width100 paddingTop10 paddingBottom10 borderTop borderBottom"
              }
              gutter={16} // Adds spacing between columns
            >
              {/* SKU Column */}
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text
                      className={
                        isDesktop
                          ? "secondary font14 poppinsSemiBold font600"
                          : "font12 secondary font600"
                      }
                    >
                      SKU {isDesktop ? <span>:</span> : null}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text className="secondaryDark">
                      {ProductDetail?.variants?.[0]?.sku
                        ? ProductDetail?.variants?.[0]?.sku
                        : "N/A"}
                    </Text>
                  </Col>
                </Row>
              </Col>

              {/* Brand Column */}
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text
                      className={
                        isDesktop
                          ? "secondary font14 poppinsSemiBold font600"
                          : "font12 secondary font600"
                      }
                    >
                      Brand {isDesktop ? <span>:</span> : null}
                    </Text>
                  </Col>
                  <Col span={24}>
                    {ProductDetail?.brand?.map((brand, key) => (
                      <Link href={`/${brand?.handle}`} key={key}>
                        <Text className="secondaryDark">
                          {brand?.title ? brand?.title : "N/A"}
                        </Text>
                      </Link>
                    ))}
                  </Col>
                </Row>
              </Col>

              {/* Shirt Fabric Column */}
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text
                      className={
                        isDesktop
                          ? "secondary font14 poppinsSemiBold font600"
                          : "font12 secondary font600"
                      }
                    >
                      Shirt Fabric {isDesktop ? <span>:</span> : null}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text className="secondaryDark">
                      {Fabric?.value ? Fabric?.value : "N/A"}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col
            className=" price-main backgroundLight"
            style={{
              padding: "5px",
              paddingBottom: "5px",
              marginBottom: "10px",
            }}
          >
            {removeCommasAndConvertToNumber(
              ProductDetail?.variants[0]?.variant_detail?.discounted_price
            ) <=
            removeCommasAndConvertToNumber(
              ProductDetail?.variants[0]?.variant_detail?.original_price
            ) ? (
              <Row style={{ overflow: "hidden" }} gutter={[10, 0]}>
                <Col span={24}>
                  <Text className={isDesktop ? "font18Bold" : "font16Bold"}>
                    {defaultCurrency}
                    &nbsp;
                    {handleProductDiscountedPrice()}
                  </Text>
                </Col>

                {removeCommasAndConvertToNumber(
                  ProductDetail?.variants[0]?.variant_detail?.discounted_price
                ) <
                removeCommasAndConvertToNumber(
                  ProductDetail?.variants[0]?.variant_detail?.original_price
                ) ? (
                  <Space className="paddingLeft5">
                    <Text className="priceCut">
                      {defaultCurrency}
                      &nbsp;
                      {handleProductOrignalPrice()}
                    </Text>
                    <Tag
                      className={
                        !isDesktop
                          ? "font14 discountTag alignCenter borderNone borderRadius0"
                          : "discountTag alignCenter borderNone borderRadius0"
                      }
                    >
                      {handlePercentageOff()}
                    </Tag>
                  </Space>
                ) : null}
              </Row>
            ) : null}
          </Col>

          {/* <Space
            align="center"
            className="width100 flexBetween marginTop10 marginBottom10"
          >
            <Space align="center">
              <Image src={Bell} alt="Bell Icon" />
              <Text className={isDesktop ? "font20Bold" : "font16Bold"}>
                Sold Out
                <span className={isDesktop ? "font14" : "font12"}>
                  {" "}
                  This item is currently out of stock
                </span>
              </Text>
            </Space>
          </Space> */}
        </Space>
      </Col>
      {!hideNotifyForm ||
        (isDesktop && (
          <Col span={24}>
            <Form
              size="large"
              name="notify_form"
              initialValues={{
                remember: true,
              }}
              onFinish={notifyUser}
              // onFinishFailed={onLoginFailed}
              autoComplete="off"
              style={{
                width: "100%",
              }}
            >
              <Col>
                <Form.Item
                  name="full_name"
                  style={{ margin: "10px 0px" }}
                  rules={[
                    { required: true, message: "Please enter a valid name!" },
                    // { min: 3, message: "Too Short!" },
                    { max: 40, message: "Too Long!" },
                    {
                      pattern: /^[aA-zZ\s]+$/,
                      message: "Only alphabets are allowed for this field ",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Full Name"
                    value={formData?.full_name}
                    onChange={(e) => {
                      handleFormChange("full_name", e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  style={{ margin: "10px 0px" }}
                  rules={[
                    {
                      required: requiredFormState,
                      message: "Please enter a valid email!",
                    },
                    {
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="John@example.com"
                    value={formData?.email}
                    onChange={(e) => {
                      handleFormChange("email", e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  //   className={isMobile || isTablet ? "marginBottom10" : ""}
                  name="phoneNo"
                  rules={[
                    {
                      required: requiredFormState,
                    },
                  ]}
                >
                  <PhoneInput
                    onChange={(e) => {
                      handleFormChange("phoneNo", e);
                    }}
                    country={"pk"}
                    value={formData?.phoneNo}
                    // onChange={(phone) => this.setState({ phone })}
                    // country={SelectedCountry?.flag?.toLowerCase()}
                    // value={userInformation?.shipping_address?.phone}
                  />
                </Form.Item>
              </Col>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{
                    height: "40px",
                    fontWeight: 600,
                    fontSize: "16px",
                    // minWidth: "422px",
                    marginTop: "20px",
                    borderRadius: "6px",
                    background: notifyUserSuccess ? "#008605" : "black",
                  }}
                >
                  {notifyUserSuccess ? (
                    <Image
                      height="auto"
                      width={20}
                      src="/notified.png"
                      style={{ marginRight: "10px", marginBottom: "4px" }}
                    />
                  ) : !notifyUserLoader ? (
                    <Image
                      height="auto"
                      width={20}
                      src="/notify.png"
                      style={{ marginRight: "10px", marginBottom: "4px" }}
                      preview={false}
                    />
                  ) : (
                    <Spin
                      indicator={
                        <RedoOutlined
                          spin
                          className="spinner-redo-white"
                          fontSize={"20px"}
                          style={{
                            collor: "white",
                          }}
                        />
                      }
                    />
                  )}

                  {notifyUserLoader
                    ? "Notify..."
                    : notifyUserSuccess
                    ? "Restock Request Received!"
                    : "Notify on Restock"}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        ))}
    </Col>
  );
}
