"use client";

//? Library Imports ------------------------------------------------------------>
import Cookies from "js-cookie";
import { Col, Row, Modal, Button, Image } from "antd";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Head from "next/head";

//? Assets --------------------------------------------------------------------->
import "./ProductPage.scss";

//? Modules -------------------------------------------------------------------->
import SoldOut from "./Modules/SoldOut/SoldOut";
// import AccountStatus from "@/functions/Cookies/Cookies";
import BreadCrumbs from "../shared/BreadCrumbs/BreadCrumbs";
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
// import SimilarProducts from "./Modules/SimilarProducts/SimilarProducts";
// import ProductImagesPanel from "./Modules/ProductImagesPanel/ProductImagesPanel";
// import ProductDetailPanel from "./Modules/ProductDetailPanel/ProductDetailPanel";
// import Loading from "@/app/loading";
// import FBPixels from "../SEO/FBPixels/FBPixels";
// import GoogleTagManager from "../SEO/GTag/Gtag";
import CustomSkeletonBox from "../CustomSkeletonBox/CustomSkeletonBox";
import CustomParagraphSkeleton from "../CustomSkeletonLines/CustomSkeletonLines";
import useBrowserBackButton from "../globalBackButton/BackButton";
import { setDefaultCountry } from "@/redux/Slices/multiLocationSlice";
import { removeCommasAndConvertToNumber } from "@/functions/HelperFunctions";
import ProductDetailPanelModal from "./Modules/ProductDetailPanel/ProductDetailPanelModal";

export default function FetchSingleProductModal({
  handle,
  userAgent,
  ProductDetail,
  storeId,
  defaultCookiesCurrencyValue,
  defaultCookiesCountryValue,
  visible,
  onClose,
}) {
  //? Hooks -------------------------------------------------------------------->
  const { isMobile, isTablet, isDesktop } = userAgent;
  // const {
  //   loggedIn,
  //   comverse_customer_id,
  //   comverse_customer_email,
  //   comverse_customer_token,
  // } = AccountStatus();

  //? Redux States --------------------------------------------------------------->

  //? States ------------------------------------------------------------------->

  if (!Cookies.get("defaultCurrencyId")) {
    Cookies.set("defaultCurrencyId", storeId, {
      expires: 365,
    });
  }
  if (!Cookies.get("defaultCurrencyName")) {
    Cookies.set("defaultCurrencyName", defaultCookiesCurrencyValue, {
      expires: 365,
    });
  }
  if (!Cookies.get("defaultCountry")) {
    Cookies.set("defaultCountry", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  if (!Cookies.get("geoLocation")) {
    Cookies.set("geoLocation", defaultCookiesCountryValue, {
      expires: 365,
    });
  }

  const defaultCurrencyIdFromCookies =
    storeId || Cookies.get("defaultCurrencyId");
  const defaultCurrencyNameFromCookies =
    defaultCookiesCurrencyValue || Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const dispatch = useDispatch();

  const [SelectedVariant, setSelectedVariant] = useState(null);
  const [productDetailRes, setProductDetailRes] = useState(ProductDetail);
  const [availableVariants, setAvailableVariants] = useState();
  //? Functions ------------------------------------------------------------------>

  useBrowserBackButton(() => {
    // console.log("Browser back button pressed");
  });

  let collectionName = localStorage?.getItem("collection_name")
    ? localStorage?.getItem("collection_name")
    : ProductDetail?.category[0].title;
  let defaultCountryId;

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };

  const fetchProduct = async (handle) => {
    try {
      const res = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/product/${handle}?storefront=23`,
        { cache: "no-store" }
      );

      const productDetailRess = await res.json();
      setProductDetailRes(productDetailRess);
      // setSelectedVariant(productDetailRess?.variants[0]);

      const { options, variants } = productDetailRess;

      if (!res.ok) {
        throw new Error("ProductDetail API Failed!");
      }

      const productOptions = options.map((option) => ({
        id: option.id,
        name: option.name,
        position: option.position,
        values: option.values.split(","),
      }));

      let selectedVariant = {};

      if (productOptions?.length) {
        for (const option of productOptions) {
          const variant = variants.find(
            (variant) =>
              variant["option" + option.position] ===
              option.values.find(
                (val) =>
                  variant["option" + option.position] === val &&
                  variant?.variant_detail?.sold_out === false
              )
          );

          if (variant) {
            selectedVariant = variant;
            break; // Stop the loop if a suitable variant is found
          }
        }
      }

      return {
        ProductDetail: { options, variants },
        SelectedVariant: selectedVariant,
      };
    } catch (error) {
      console.error("ProductDetail API Failed!", error.message);
      throw error;
    }
  };

  const initializeData = async () => {
    try {
      const geoLocationUrl =
        "https://pro.ip-api.com/json/?fields=61439&key=r8KxA4oLfB365n8";
      const countryDataUrl = `${process.env.NEXT_PUBLIC_BACKEND}/storefront/store_list`;

      const apiData = await fetchData(geoLocationUrl);

      const countryListData = await fetchData(countryDataUrl);

      const matchingCountry = countryListData?.country_list.find(
        (countryItem) => countryItem.country === apiData.country
      );

      if (matchingCountry) {
        Cookies.set("defaultCurrencyName", matchingCountry.currency, {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", matchingCountry.id, {
          expires: 365,
        });

        defaultCountryId = matchingCountry.id;

        Cookies.set("defaultCountry", matchingCountry.country, {
          expires: 365,
        });

        Cookies.set("geoLocation", matchingCountry.country, {
          expires: 365,
        });

        dispatch(setDefaultCountry(matchingCountry));
        dispatch(changeCountryId(matchingCountry.id));
        dispatch(changeCountry(matchingCountry.country));
        dispatch(changeCountryCode(matchingCountry.short_code));
        dispatch(changeCurrency(matchingCountry.currency));
      } else {
        Cookies.set("defaultCurrencyName", "USD", {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", "35", {
          expires: 365,
        });

        Cookies.set("defaultCountry", "United States", {
          expires: 365,
        });

        Cookies.set("geoLocation", apiData.country, {
          expires: 365,
        });
        defaultCountryId = 35;
        dispatch(setDefaultCountry("United States"));
        dispatch(changeCountryId("35"));
        dispatch(changeCountry("United States"));
        dispatch(changeCurrency("USD"));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchProduct(handle);

    // if (!defaultCurrencyIdFromCookies) {
    //   fetchDataAndInitialize();
    // }

    // const baseUrl = window.location.href.split("/")[0];
    // const newPath = `${baseUrl}/${handle}`;

    // history.pushState({}, "", newPath);
  }, [handle]);

  const availableVariant = ProductDetail?.variants.find((variant) => {
    return variant?.variant_detail?.sold_out === false;
  });

  useEffect(() => {
    if (availableVariant && !ProductDetail?.is_stitch) {
      setAvailableVariants(availableVariant);
    } else {
      setSelectedVariant(availableVariant);
    }
  }, []);

  const handleDiscount = (productDetailRes) => {
    const variant = productDetailRes?.variants?.[0]?.variant_detail;
    const discountedPrice = removeCommasAndConvertToNumber(
      variant?.discounted_price
    );
    const originalPrice = removeCommasAndConvertToNumber(
      variant?.original_price
    );

    return originalPrice > discountedPrice
      ? originalPrice - discountedPrice
      : undefined;
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window?.dataLayer) {
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          currency: defaultCurrencyNameFromCookies,
          items: [
            {
              item_id: productDetailRes?.id,
              item_name: productDetailRes?.title,
              discount: handleDiscount(productDetailRes),
              item_brand: productDetailRes?.brand[0]?.title ?? "No Brand",
              item_variant: productDetailRes?.variants[0]?.variant_id,
              item_category: collectionName ? collectionName : undefined,
              location_id: defaultgeoLocationCountry,
              price: removeCommasAndConvertToNumber(
                productDetailRes?.variants[0]?.variant_detail?.discounted_price
              ),
            },
          ],
        },
      });
    }
  }, [productDetailRes]);

  return (
    <Modal
      title={ProductDetail?.title}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      width={600}
    >
      <Col>
        <Col className="ProductPage">
          {!productDetailRes?.sold_out ? (
            <Col
              span={24}
              className={
                isMobile || isTablet
                  ? ""
                  : isDesktop
                  ? "marginLeft20 marginRight20"
                  : ""
              }
            >
              <Row
                style={{ height: "auto", minWidth: "100%", maxWidth: "100%" }}
                className={
                  isMobile || isTablet
                    ? "paddingLeft10 paddingRight10"
                    : isDesktop
                    ? "positionRelative flexCenter backgroundLight paddingTop15 paddingBottom15 paddingRight10 paddingLeft10"
                    : ""
                }
              >
                <Row>
                  <Col span={24}>
                    {!defaultCurrencyIdFromCookies ||
                    !productDetailRes?.images?.length ? (
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1, marginRight: 8 }}>
                          <CustomSkeletonBox
                            width="100%"
                            height={isMobile ? 350 : 500}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <Image
                          height={300}
                          width="auto"
                          preview={false}
                          src={productDetailRes?.images[0]?.cdn_link}
                        />
                        {/* {isDesktop && (
                          <Image
                            height={300}
                            width="auto"
                            preview={false}
                            src={productDetailRes?.images[1]?.cdn_link}
                          />
                        )} */}
                      </>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col span={24}>
                    {!defaultCurrencyIdFromCookies || !productDetailRes ? (
                      <CustomParagraphSkeleton width="100" />
                    ) : (
                      <ProductDetailPanelModal
                        handle={handle}
                        userAgent={userAgent}
                        ProductDetail={productDetailRes}
                        SelectedVariant={SelectedVariant}
                        setSelectedVariant={setSelectedVariant}
                        availableVariants={availableVariants}
                      />
                    )}
                  </Col>
                </Row>
              </Row>
            </Col>
          ) : (
            <Col
              span={24}
              className={
                !isDesktop
                  ? "SoldOutView"
                  : "SoldOutView marginLeft20 marginRight20 marginTop10"
              }
            >
              <SoldOut
                userAgent={userAgent}
                ProductDetail={productDetailRes}
                SelectedVariant={SelectedVariant}
              />
            </Col>
          )}
        </Col>
      </Col>
    </Modal>
  );
}
