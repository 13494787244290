"use client";
import Link from "next/link";
import { Button } from "antd";

export default function DynamicButton(props) {
  return props?.link ? (
    <Link href={props?.link}>
      <Button
        block
        icon={props?.icon}
        type={props?.type}
        form={props?.form}
        danger={props?.property}
        onClick={props?.function}
        htmlType={props?.htmlType}
        disabled={props?.disabled}
        className={props?.className}
        style={{
          gap: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...props?.style,
        }}
      >
        {props?.name}
      </Button>
    </Link>
  ) : (
    <Button
      block
      icon={props?.icon}
      type={props?.type}
      form={props?.form}
      danger={props?.property}
      onClick={props?.function}
      htmlType={props?.htmlType}
      disabled={props?.disabled}
      className={props?.className}
      style={{
        gap: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...props?.style,
      }}
    >
      {props?.name}
    </Button>
  );
}
