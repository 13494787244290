import { createSlice } from "@reduxjs/toolkit";

export const checkoutSlice = createSlice({
  name: "checkoutSlice",
  initialState: {
    checkoutId: null,
    onCheckout: false,
    // checkoutObj: {},
    userInformation: {},
  },
  reducers: {
    setOnCheckout: (state, action) => {
      state.onCheckout = action.payload;
    },

    setCheckoutId: (state, action) => {
      state.checkoutId = action.payload;
      localStorage.setItem("checkout_id", action.payload);
    },

    // setCheckout: (state, action) => {
    //   state.checkoutObj = action.payload;
    // },

    clearCheckout: (state) => {
      state.checkoutId = null;
      //   state.checkoutObj = {};
      localStorage.removeItem("checkout_id");
      localStorage.removeItem("billingAddress");
      localStorage.removeItem("shippingAddress");
    },

    setUserInformation: (state, action) => {
      state.userInformation = action.payload;
    },
  },
});

export const {
  setOnCheckout,
  setCheckoutId,
  clearCheckout,
  setUserInformation,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
