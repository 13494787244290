"use client";
import React, { useState, useEffect } from "react";
import { Button, Space, Drawer, Radio, Col, message } from "antd";
import SizeChartDrawer from "../SizeChart/SizeChartDrawer/SizeChartDrawer";
import "./ProductSizeBottomDrawer.scss";
const ProductSizeBottomDrawer = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSizeChart, setOpenSizeChart] = useState(false);
  const onClose = () => {
    props?.setOpenDrawer(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeValue = (option) => {
    let value = { ...props?.selectedValueFromOptions };
    value.option1 = option;
    props?.setSelectedValueFromOptions({ ...value });
  };

  const handleSubmitSize = () => {
    if (props?.selectedValueFromOptions?.option1 === "-9346535324534") {
      message.error("Please select a size.");
    } else {
      onClose();
      props?.addProduct();
      props?.setViewBagItemsState(true);
      props?.setaddToCartLoader(true);
    }
  };

  useEffect(() => {
    let value = { ...props?.SelectedVariant };
    value.option1 = "-9346535324534";
    props?.setSelectedValueFromOptions({ ...value });
  }, [props?.openDrawer]);

  return (
    <div className="product-size-bottom-drawer-main" style={{ zIndex: 1 }}>
      <Drawer
        style={{ zIndex: 1 }}
        className="product-size-bottom-drawer-main"
        title="Select Size"
        placement="bottom"
        width={500}
        height={227}
        onClose={onClose}
        open={props?.openDrawer}
        // extra={
        //   <Space>
        //     <div className="size-chart-heading">
        //       <SizeChartDrawer
        //         ProductDetail={props?.ProductDetail}
        //         SelectedVariant={props?.SelectedVariant}
        //         setSelectedVariant={props?.setSelectedVariant}
        //         SizeChart={props?.ProductDetail?.size_chart}
        //         availableVariants={props?.availableVariants}
        //         ProductQuantity={props?.ProductQuantity}
        //         setProductQuantity={props?.setProductQuantity}
        //         viewBagItemsState={props?.viewBagItemsState}
        //         setViewBagItemsState={props?.setViewBagItemsState}
        //         userAgent={props?.userAgent}
        //         LineItemAddons={props?.LineItemAddons}
        //         setLineItemAddons={props?.setLineItemAddons}
        //         openDrawer={props?.openDrawer}
        //         setOpenDrawer={props?.setOpenDrawer}
        //         showDrawersizechart={props?.showDrawer}
        //         addProduct={props?.addProduct}
        //         pushedVariants={props?.pushedVariants}
        //         setPushedVariants={props?.setPushedVariants}
        //         handleOptionChange={props?.handleOptionChange}
        //         addToCartLoader={props?.addToCartLoader}
        //         setaddToCartLoader={props?.setaddToCartLoader}
        //         selectedValueFromOptions={props?.selectedValueFromOptions}
        //         setSelectedValueFromOptions={props?.setSelectedValueFromOptions}
        //         handleProductDiscountedPrice={
        //           props?.handleProductDiscountedPrice
        //         }
        //         handleProductOrignalPrice={props?.handleProductOrignalPrice}
        //         closeDrawerSize={onClose}
        //         productBottomDrawer
        //       />
        //     </div>
        //   </Space>
        // }
      >
        <div>
          <div className="custom-radio-group">
            {props?.ProductDetail?.variants.map((item, index) => {
              if (item?.variant_detail?.sold_out) {
                return (
                  <div key={index}>
                    <button
                      className={`option ${"button-sold-out-drawer"}`}
                      type="button"
                    >
                      {item?.option1}
                    </button>
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      props?.handleOptionChange(item?.option1);
                      handleChangeValue(item?.option1);
                    }}
                  >
                    <button
                      className={`option ${
                        props?.selectedValueFromOptions?.option1 ===
                        item?.option1
                          ? "button selected"
                          : "button"
                      }`}
                      type="button"
                    >
                      {item?.option1}
                    </button>
                  </div>
                );
              }
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                handleSubmitSize();
              }}
              style={{
                width: "184px",
                height: "33px",
                textTransform: "uppercase",
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              DONE
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default ProductSizeBottomDrawer;
