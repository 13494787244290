import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cartSlice",
  initialState: {
    cart: [],
    cartId: null,
    cartTotal: 0,
    cartCount: 0,
    showCart: false,
    refreshCart: null,
    cartCountTotal: 0,
  },
  reducers: {
    setCartId: (state, action) => {
      state.cartId = action.payload;
      localStorage.setItem("cartId", JSON.stringify(state.cartId));
    },
    addToCart: (state, action) => {
      state.cart = action.payload;
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    removeFromCart: (state, action) => {
      state.cart = action.payload;
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    updateCart: (state, action) => {
      // if (action.payload) {
      //
      //   state.cart = action.payload;
      //   localStorage.setItem("cart", JSON.stringify(state.cart));
      // }

      // let tempCart = state.cart;
      // let totalPrice = 0;
      // let totalCount = 0;

      // for (let i = 0; i < tempCart?.length; i++) {
      //   const cartItem = tempCart[i];

      //   totalCount += parseInt(cartItem?.quantity);
      //   totalPrice += cartItem?.variant_price * cartItem?.quantity;
      // }

      // state.cartCount = totalCount;
      // state.cartTotal = totalPrice;

      let tempCart = state.cart || []; // Set a default value for tempCart in case state.cart is undefined
      let totalPrice = 0;
      let totalCount = 0;

      for (let i = 0; i < tempCart.length; i++) {
        const cartItem = tempCart[i];
        totalCount += parseInt(cartItem.productVariant.variantQuantity || 0); // Use parseInt to convert string to number and set default value to 0 if quantity is undefined
        // totalPrice += (cartItem.variant_price || 0) * (cartItem.quantity || 0); // Use default value of 0 if variant_price or quantity is undefined
      }

      state.cartCount = totalCount;
      state.cartTotal = totalPrice;
    },
    clearCart: (state) => {
      state.cart = [];
      state.cartId = null;
      state.cartCount = 0;
      state.cartTotal = 0;
      localStorage.removeItem("cartId");
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    displayCart: (state, action) => {
      state.showCart = action.payload;
    },
    refreshCart: (state, action) => {
      state.refreshCart = action.payload;
    },
    updateCartCount: (state, action) => {
      state.cartCountTotal = action.payload;
    },
  },
});

export const {
  setCartId,
  updateCart,
  addToCart,
  removeFromCart,
  clearCart,
  displayCart,
  refreshCart,
  updateCartCount,
} = cartSlice.actions;
export default cartSlice.reducer;
