import React, { useState, useEffect } from "react";
import { Button, message } from "antd";

import "./ProductSizeBottomDrawer.scss";

const SizeChartBottomStitchedDrawer = (props) => {
  const onClose = () => {
    props?.setOpenDrawer(false);
  };

  const handleChangeValue = (option) => {
    let value = { ...props?.selectedValueFromOptions };
    value.option1 = option;
    props?.setSelectedValueFromOptions({ ...value });
  };

  const handleSubmitSize = () => {
    if (props?.selectedValueFromOptions?.option1 === "-9346535324534") {
      message.error("Please select a size.");
    } else {
      onClose();
      props?.addProduct();
      props?.setViewBagItemsState(true);
      props?.setaddToCartLoader(true);
    }
  };

  useEffect(() => {
    let value = { ...props?.SelectedVariant };
    value.option1 = "-9346535324534";
    props?.setSelectedValueFromOptions({ ...value });
  }, [props?.openDrawer]);

  return (
    <>
      {props.openDrawer && (
        <div
          className="product-size-bottom-drawer-main"
          style={{ zIndex: 1000 }}
        >
          <div className="drawer-content">
            <div className="title-and-close-container">
              <div className="drawer-content-title">Select Size</div>
              <div className="close-button">
                <Button type="secondary" onClick={onClose}>
                  X
                </Button>
              </div>
            </div>
            <div className="custom-radio-group">
              {props?.ProductDetail?.variants.map((item, index) => {
                if (item?.variant_detail?.sold_out) {
                  return (
                    <div key={index}>
                      <button
                        className={`option button-sold-out-drawer`}
                        type="button"
                      >
                        {item?.option1}
                      </button>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        props?.handleOptionChange(item?.option1);
                        handleChangeValue(item?.option1);
                      }}
                    >
                      <button
                        className={`option ${
                          props?.selectedValueFromOptions?.option1 ===
                          item?.option1
                            ? "button selected"
                            : "button"
                        }`}
                        type="button"
                      >
                        {item?.option1}
                      </button>
                    </div>
                  );
                }
              })}
            </div>
            <div className="centered-button">
              <Button
                type="primary"
                onClick={() => {
                  handleSubmitSize();
                }}
                style={{
                  width: "184px",
                  height: "33px",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                DONE
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SizeChartBottomStitchedDrawer;
