"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import Image from "next/image";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/navigation";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ProductSizeBottomDrawer from "../ProductSizeBottomDrawer/ProductSizeBottomDrawer";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { logoutReducer } from "@/redux/Slices/accountSlice";
import { customerLogout } from "@/functions/Cookies/Cookies";
import { removeCommasAndConvertToNumber } from "../../../../functions/HelperFunctions/index";
import { getCurrentDateTime } from "../../../../functions/HelperFunctions/index";
import { RedoOutlined } from "@ant-design/icons";
import handBag from "../../../../assets/images/CartPage/handbag.png";
// import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import "../ProductSizeBottomDrawer/ProductSizeBottomDrawer.scss";
import { useState, useEffect, useMemo, useCallback } from "react";
import {
  Col,
  Row,
  Tag,
  Form,
  Spin,
  Input,
  Modal,
  Space,
  Button,
  Divider,
  message,
  Typography,
  InputNumber,
  notification,
} from "antd";

//? Assets --------------------------------------------------------------------->
import {
  HeartFilled,
  HeartOutlined,
  PhoneOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { HiOutlineShoppingBag } from "react-icons/hi";
import Ship from "../../../../assets/images/ProductPage/cod-logo.png";
import Clock from "../../../../assets/images/ProductPage/Clock.svg";
import Return from "../../../../assets/images/ProductPage/Return.svg";
import Secure from "../../../../assets/images/ProductPage/Secure.svg";
import WhatsApp from "../../../../assets/images/ProductPage/Chat.svg";
import WorldWide from "../../../../assets/images/ProductPage/WorldWide.svg";
import OnlineOnly from "../../../../assets/images/ProductPage/OnlineOnly.svg";
import Points from "../../../../assets/images/logos/AlfaPoints.svg";
import "./ProductDetailPanel.scss";

//? Modules -------------------------------------------------------------------->
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import DynamicButton from "@/components/Shared/Buttons/DynamicButton/DynamicButton";
import svgLoader from "../../../../assets/images/addToCartAnimation/rotating-arrow.svg";

const ProductOptions = dynamic(() => import("./ProductOptions/ProductOptions"));
const StitchingModule = dynamic(
  () => import("../StitchingModule/StitchingModule"),
  { ssr: false }
);
const ProductDescription = dynamic(() =>
  import("./ProductDescription/ProductDescription")
);
import LoginPage from "@/components/SignUpPage/LoginPage";

//? Redux Reducers ------------------------------------------------------------->
import {
  displayCart,
  updateCartCount,
  clearCart,
  updateCart,
} from "@/redux/Slices/cartSlice";
import AccountStatus from "@/functions/Cookies/Cookies";
import { addToWishList, displayWishlist } from "@/redux/Slices/wishListSlice";
import {
  // setCheckoutId,
  clearCheckout,
  setUserInformation,
} from "@/redux/Slices/checkoutSlice";

// import { setCartId, addToCart, updateCart } from "@/redux/Slices/cartSlice";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function ProductDetailPanelModal({
  userAgent,
  ProductDetail,
  SelectedVariant,
  setSelectedVariant,
  availableVariants,
}) {
  //? Redux States --------------------------------------------------------------->
  let cartId = Cookies.get("cartId");
  const FacebookPixelId = Cookies.get("_fbp");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  let defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  if (!defaultgeoLocationCountry) {
    defaultgeoLocationCountry = defaultCountryFromCookies;
    Cookies.set("geoLocation", defaultCountryFromCookies, {
      expires: 365,
    });
  }
  const defaultCurrency = defaultCurrencyFromCookies;
  const StoreId = defaultCurrencyIdFromCookies;
  const cart = useSelector((state) => state?.cartSlice?.cart);
  // const cartId = useSelector((state) => state?.cartSlice?.cartId);
  const wishList = useSelector((state) => state?.wishListSlice?.wishList);
  const comverse_customer_name = useSelector(
    (state) => state?.accountSlice?.comverse_customer_name
  );
  // const comverse_customer_token = useSelector(
  //   (state) => state.accountSlice.comverse_customer_token
  // );

  //? States ------------------------------------------------------------------->
  const [userAgentFromCookies, setuserAgentFromCookies] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [ProductQuantity, setProductQuantity] = useState(1);
  const [ProductInCart, setProductInCart] = useState(false);
  const [ShowNotifyModal, setShowNotifyModal] = useState(false);
  const [ProductInWishList, setProductInWishList] = useState(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [selectedValueFromOptions, setSelectedValueFromOptions] =
    useState(SelectedVariant);
  const [ShowLoginModal, setShowLoginModal] = useState(false);
  const [selectSizeError, setSelectSizeError] = useState(false);

  //? Drawer State ------------------------------------------------------------------->

  const [openDrawer, setOpenDrawer] = useState(false);
  const [viewBagItemsState, setViewBagItemsState] = useState(false);
  const [pushedVariants, setPushedVariants] = useState([]);
  const [addToCartLoader, setaddToCartLoader] = useState(false);

  //? ProductOptions Module States ---------------------------------------------->
  const [LineItemAddons, setLineItemAddons] = useState([]);
  const [TotalAddonPrice, setTotalAddonPrice] = useState(0);

  //? Stitching Module States --------------------------------------------------->
  const [EnableStitching, setEnableStitching] = useState(false);
  const [DefaultStitching, setDefaultStitching] = useState([]); // * Product Stitching Options Selected by Default.
  const [showCopyDiv, setShowCopyDiv] = useState(false);
  const [priorCategoryName, setPriorCategoryName] = useState(null);
  const [productSoldout, setProductSoldout] = useState(false);
  const [isSizeChartOpen, setIsSizeChartOpen] = useState(false);

  //? Hooks --------------------------------------------------------------------->
  const router = useRouter();
  const dispatch = useDispatch();
  const uniqueProductId = uuidv4().toString();
  // const { isMobile, isTablet, isDesktop } = MediaQueryHook();
  const { isMobile, isTablet, isDesktop } = userAgent;
  let {
    loggedIn,
    comverse_customer_id,
    comverse_customer_email,
    comverse_customer_token,
  } = AccountStatus();

  const showDrawer = () => {
    if (viewBagItemsState) {
      router.push(`/cart/checkout?id=${cartId}`);
    } else {
      if (ProductDetail?.is_stitch) {
        setaddToCartLoader(true);
        addProduct();
        setViewBagItemsState(true);
      } else if (selectedValueFromOptions?.option1 === "-9346535324534") {
        setOpenDrawer(true);
      } else {
        setaddToCartLoader(true);
        addProduct();
        setViewBagItemsState(true);
      }
    }
  };

  const Fabric = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Shirt Fabric"
      ),
    [ProductDetail?.features]
  );
  const OnlineAvailable = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Online Only"
      ),
    [ProductDetail?.features]
  );

  const CashOnDelivery = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Cash On Delivery"
      ),
    [ProductDetail?.features]
  );
  const WorldWideShipping = useMemo(
    () =>
      ProductDetail?.features?.find(
        (feature) => feature?.name === "Worldwide Shipping"
      ),
    [ProductDetail?.features]
  );
  const GiftWrap = useMemo(
    () =>
      ProductDetail?.features?.find((feature) => feature?.name === "Gift Wrap"),
    [ProductDetail?.features]
  );
  const Color = useMemo(
    () => ProductDetail?.features?.find((feature) => feature?.name === "Color"),
    [ProductDetail?.features]
  );
  const customerEmail = useState(
    useSelector((state) => state.accountSlice.comverse_customer_email)
  );
  const currentDateTime = getCurrentDateTime();
  const [productViewCounter, setProductViewCounter] = useState(1);

  //? Functions ------------------------------------------------------------------>

  const handleClickAway = () => {
    setOpenSharePopup(false);
  };
  const handleClick = () => {
    setOpenSharePopup((prev) => !prev);
  };

  const productIncart = useCallback(() => {
    const variantId = SelectedVariant?.variant_detail?.variant_id;
    let variant = cart?.find(
      (item) => item?.productVariant?.variantId === variantId
    );

    if (variant) {
      setProductInCart(true);
    } else {
      setProductInCart(false);
    }
  }, [SelectedVariant, cart]);

  const quantityIncrement = () => {
    if (ProductDetail?.quantity_limit) {
      if (ProductQuantity < ProductDetail?.quantity_limit) {
        setProductQuantity(ProductQuantity + 1);
      } else {
        message.error("Maximum quantity exceeded");
      }
    } else if (!ProductDetail?.quantity_limit) {
      if (ProductQuantity) {
        setProductQuantity(ProductQuantity + 1);
      } else {
        message.error("Maximum quantity exceeded");
      }
    } else {
      message.error("Maximum quantity exceeded");
    }
    // setProductQuantity(ProductQuantity + 1);
  };

  const quantityDecrement = () => {
    if (ProductQuantity > 1) {
      setProductQuantity(ProductQuantity - 1);
    }
  };
  const copyTextToClipboard = async () => {
    setShowCopyDiv(true);
    try {
      await navigator.clipboard.writeText(window.location.href);
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
      // Handle the error or provide user feedback
    }
    setTimeout(() => {
      setShowCopyDiv(false);
    }, 2000);
  };

  const notifyCartUpdate = (type) => {
    if (type === "success") {
      notification[type]({
        message: "Added to Cart!",
      });
    } else {
      notification[type]({
        message: "Removed from Cart!",
      });
    }
  };

  const getWishlist = useCallback(async () => {
    if (loggedIn && comverse_customer_token) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const payload = {
          method: "GET",
          headers: headers,
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/wishlist_list?storefront=${StoreId}`,
          payload
        );
        const data = await res.json();

        if (!res.ok) {
          // message.error("Failed to get Wishlist!");
          if (data?.detail === "Invalid auth token") {
            customerLogout();
            dispatch(logoutReducer());
          }
          throw new Error("Api Failed!");
        } else {
          // message.success("Product Added to Wishlist!");
          const found = data.results?.find(
            (wishListItem) => wishListItem?.id === ProductDetail?.id
          );
          if (found) {
            setProductInWishList(true);
          }
          return data;
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [ProductDetail?.id, StoreId, comverse_customer_token, loggedIn]);

  const addProductToWishlist = async () => {
    if (loggedIn && comverse_customer_token) {
      try {
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const body = {
          storefront: StoreId,
          product_ids: [ProductDetail?.id],
        };

        const payload = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
          "/storefront/add_product_to_wishlist",
          payload
        );
        const data = await res.json();

        if (!res.ok) {
          // message.error("Failed to add in Wishlist!");
          throw new Error("Api Failed!");
        } else {
          message.success("Product Added to Wishlist!");
          setProductInWishList(true);
          dispatch(displayWishlist(true));
          setLoading(false);
          const itemsData = data?.map((item) => ({
            item_id: item?.id ?? null,
            item_name: item?.title,
            item_variant: item?.variants[0]?.id,
            item_brand: item?.brand[0]?.title,
            price: item?.variants[0]?.variant_detail?.discounted_price,
            location_id: defaultgeoLocationCountry,
          }));

          if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
            if (typeof window !== "undefined" && window?.dataLayer) {
              dataLayer.push({ ecommerce: null });
              dataLayer.push({
                event: "add_to_wishlist",
                ecommerce: {
                  currency: defaultCurrency ? defaultCurrency : "PKR",
                  Items: itemsData,
                },
              });
            }
          }
          return data;
        }
      } catch (error) {
        console.log("error", error);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const handleOptionChange = (option, check) => {
    // props?.ProductDetail?.variants?.map((item) => {
    //   if (item["option1"] === option) {
    //     props?.setSelectedVariant(item);
    //   }
    // });
    const selectedVariant = ProductDetail?.variants?.find(
      (item) => item["option1"] === option
    );
    if (selectedVariant && check) {
      const findPushedVariant = pushedVariants?.find(
        (item) => item["option1"] === option
      );
      if (!findPushedVariant) {
        let newArray = [...pushedVariants];
        newArray.push(selectedVariant);
        setPushedVariants([...newArray]);
      }
    } else if (selectedVariant) {
      setSelectedVariant(selectedVariant);
    }
  };

  const removeProductFromWishlist = async () => {
    if (loggedIn && comverse_customer_token) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const body = {
          storefront: StoreId,
        };

        const payload = {
          method: "DELETE",
          headers: headers,
          body: JSON.stringify(body),
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/remove_product_from_wishlist?product_id=${ProductDetail?.id}`,
          payload
        );
        const data = await res.json();

        if (!res.ok) {
          // message.error("Failed to delete from Wishlist!");
          throw new Error("Api Failed!");
        } else {
          setProductInWishList(false);
          message.success("Product Deleted from Wishlist!");
          return data;
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const productInWishList = useCallback(() => {
    const product = wishList?.find(
      (product) => product?.productDetail?.id === ProductDetail?.id
    );

    if (product) {
      setProductInWishList(true);
    } else {
      setProductInWishList(false);
    }
  }, [ProductDetail?.id, wishList]);

  const clearCartDataAndBuyNow = () => {
    dispatch(clearCheckout());
    dispatch(clearCart());
    dispatch(updateCart());
    dispatch(updateCartCount(0));
    Cookies.remove("cartId");
    cartId = Cookies.get("cartId");

    buyItNow();
  };

  const buyItNow = async () => {
    const AddOns = LineItemAddons.map((lineItem) => ({
      option: lineItem?.option,
    }));
    try {
      setLoading(true);
      const body = {
        storefront: StoreId,
        line_item: {
          add_ons: AddOns,
          quantity: ProductQuantity,
          variant_price_inventory: SelectedVariant?.variant_detail?.variant_id,
        },
        ip_country: defaultgeoLocationCountry,
        user_agent: userAgentFromCookies,
      };

      const headers = {
        "Content-Type": "application/json",
      };
      if (cartId) {
        headers.cartid = cartId;
      }
      if (comverse_customer_token) {
        headers.Authorization = comverse_customer_token;
      }

      const payload = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      };

      const AddProduct = await fetch(
        process.env.NEXT_PUBLIC_BACKEND + `/order/add_item_in_cart`,
        payload
      );

      const ProductAdded = await AddProduct.json();

      if (!AddProduct.ok) {
        if (ProductAdded?.detail) {
          if (ProductAdded?.detail === "Maximum quantity exceeded") {
            message.error(ProductAdded?.detail);
          }
          if (ProductAdded?.detail === "cart not found") {
            clearCartDataAndBuyNow();
          }
          if (ProductAdded?.detail === "Invalid auth token") {
            comverse_customer_token = null;
            customerLogout();
            dispatch(logoutReducer());
            addProduct();
          }
          if (ProductAdded?.detail === "conflict") {
            message.error("conflict");
          }
          if (ProductAdded?.detail === "inventory quantity not available") {
            message.error("inventory quantity not available");
          }
        } else {
          // message.error("AddToCart Api Failed!");
        }
        throw new Error("Api Failed!");
      } else if (ProductAdded) {
        setLoading(false);
        message.success("Product Added to Cart!");
        Cookies.set("cartId", ProductAdded?.cartid);
        dispatch(updateCartCount(ProductAdded?.line_items.length));
        router.push(`/cart/checkout?id=${cartId}`);

        return ProductAdded;
      }
    } catch (error) {
      console.error("AddToCart Api Failed!", error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const calculateAddonPrice = useCallback(() => {
    let TotalPrice = 0;
    const AddOns = [];

    LineItemAddons.forEach((lineItem) => {
      ProductDetail.add_on_templates.forEach((template) => {
        template.add_ons.forEach((addOn) => {
          addOn.options.forEach((option) => {
            if (option.id === lineItem.option) {
              AddOns.push(option);
            }
          });
        });
      });
    });

    // console.log("AddOns----->", AddOns);
    AddOns.forEach((addOn) => {
      TotalPrice =
        TotalPrice + removeCommasAndConvertToNumber(addOn?.discounted_price);
    });
    // console.log("TotalPrice----->", TotalPrice);
    setTotalAddonPrice(TotalPrice);
  }, [LineItemAddons, ProductDetail?.add_on_templates]);

  function deepCompareArrays(arr1, arr2) {
    // If the arrays have different lengths, they are not equal
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    // Loop through each object in the array and compare them recursively
    for (let i = 0; i < arr1?.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      // If the objects have different keys, they are not equal
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (
        keys1?.length !== keys2?.length ||
        !keys1.every((key) => keys2.includes(key))
      ) {
        return false;
      }

      // Compare the values of each key recursively
      for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (typeof val1 === "object" && typeof val2 === "object") {
          if (!deepCompareArrays(val1, val2)) {
            return false;
          }
        } else if (val1 !== val2) {
          return false;
        }
      }
    }

    // If we made it this far, the arrays are equal
    return true;
  }

  const showNotificationModal = () => {
    setShowNotifyModal(false);
  };

  const notifyUser = async (data) => {
    try {
      const body = {
        name: data?.full_name,
        email: data?.email,
        phone: data?.phoneNo,
        variant: SelectedVariant?.variant_id,
        ip_country: defaultgeoLocationCountry,
      };

      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };

      const Notify = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
        `/storefront/add_product_to_notify_by_user?storefront=${StoreId}`,
        payload
      );

      if (!Notify.ok) {
        throw new Error("NotifyUser API Failed!");
      }

      // Handle the response data accordingly based on your use case
      if (Notify?.ok) {
        setShowNotifyModal(false);
        // console.log("Notification Enabled Successful");
        message.success("Notification Enabled Successfully!");
      } else {
        // console.log("Notification Failed");
        // message.error("Notification Enabled Failed!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  //? AddToCart New Flow --------------------------------------------------------->
  const handleDiscount = (SelectedVariant) => {
    const discountedPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.discounted_price
    );
    const originalPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.original_price
    );

    return originalPrice > discountedPrice
      ? originalPrice - discountedPrice
      : undefined;
  };
  const clearCartDataAndAddProduct = () => {
    dispatch(clearCheckout());
    dispatch(clearCart());
    dispatch(updateCart());
    dispatch(updateCartCount(0));
    Cookies.remove("cartId");
    cartId = Cookies.get("cartId");

    addProduct();
  };

  const addProduct = async () => {
    // console.log("LineItemAddons---->", LineItemAddons);
    const AddOns = LineItemAddons.map((lineItem) => ({
      option: lineItem?.option,
    }));
    let uuid = localStorage.getItem("uuid");
    // console.log("AddOns---->", AddOns);

    if (SelectedVariant && !viewBagItemsState) {
      setSelectSizeError(false);
      try {
        setLoading(true);
        setaddToCartLoader(true);
        const body = {
          storefront: StoreId,
          line_item: {
            add_ons: AddOns,
            quantity: ProductQuantity,
            item_type: priorCategoryName || "no category",
            variant_price_inventory:
              SelectedVariant?.variant_detail?.variant_id,
          },
          ip_country: defaultgeoLocationCountry,
          user_agent: userAgentFromCookies,
          // session_uuid: uuid || null
        };

        const headers = {
          "Content-Type": "application/json",
        };
        if (cartId) {
          headers.cartid = cartId;
        }
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        // console.log("headers", headers);

        const payload = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        };

        const AddProduct = await fetch(
          process.env.NEXT_PUBLIC_BACKEND + `/order/add_item_in_cart`,
          payload
        );

        const ProductAdded = await AddProduct.json();

        if (!AddProduct.ok) {
          setViewBagItemsState(false);
          setaddToCartLoader(false);
          if (ProductAdded?.detail) {
            if (ProductAdded?.detail === "Maximum quantity exceeded") {
              message.error(ProductAdded?.detail);
            }
            if (ProductAdded?.detail === "conflict") {
              message.error("conflict");
            }
            if (ProductAdded?.detail === "cart not found") {
              clearCartDataAndAddProduct();
            }
            if (ProductAdded?.detail === "Invalid auth token") {
              comverse_customer_token = null;

              customerLogout();
              dispatch(logoutReducer());
              //{"detail":"Maximum quantity exceeded"}
              addProduct();
            }
            if (ProductAdded?.detail === "inventory quantity not available") {
              message.error("inventory quantity not available");
            }
          } else {
            // message.error("AddToCart Api Failed!");
          }
          // message.error("AddToCart Failed!", ProductAdded?.detail);
          throw new Error("Api Failed!");
        } else if (ProductAdded) {
          // comverseInsight()
          setViewBagItemsState(true);
          // if (isMobile) {
          setTimeout(() => {
            setaddToCartLoader(false);
          }, 1000);
          handleOptionChange(SelectedVariant["option1"], true);
          // }
          // dispatch(setCartId(ProductAdded.cartid));
          setLoading(false);
          message.success("Product Added to Cart!");
          Cookies.set("cartId", ProductAdded?.cartid);
          // dispatch(displayCart(true));
          dispatch(updateCartCount(ProductAdded?.line_items.length));
          // notifyCartUpdate("success");
          // message.success("Added to Cart!");

          //? Facebook Pixel ----------------------------------------------------------->
          if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
            //? Google Analytics ----------------------------------------------------------->
            // if (typeof window.gtag !== "undefined") {
            //   const variantDetail = SelectedVariant?.variant_detail;
            //   const price = variantDetail?.discounted_price;

            //   window.gtag("event", "add_to_cart", {
            //     send_to: "AW-859051518",
            //     currency: defaultCurrency,
            //     value:
            //       removeCommasAndConvertToNumber(
            //         SelectedVariant?.variant_detail?.discounted_price
            //       ) * ProductQuantity,
            //     items: [
            //       {
            //         //  adding a product to a shopping cart.
            //         item_id: ProductDetail?.id,
            //         item_name: ProductDetail?.title,
            //         item_brand: ProductDetail?.brand[0]?.title ?? "No Brand",
            //         item_variant: SelectedVariant?.variant_id,
            //         quantity: ProductQuantity,
            //         item_category: priorCategoryName,
            //         discount: handleDiscount(SelectedVariant),
            //         price: removeCommasAndConvertToNumber(
            //           SelectedVariant?.variant_detail?.discounted_price
            //         ),
            //         location_id: defaultgeoLocationCountry,
            //       },
            //     ],
            //   });
            // }
            if (typeof window !== "undefined" && window?.dataLayer) {
              // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
              // and a list of productFieldObjects.
              dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
              dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  currency: defaultCurrency,
                  value:
                    removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.discounted_price
                    ) * ProductQuantity,
                  items: [
                    {
                      //  adding a product to a shopping cart.
                      item_id: ProductDetail?.id,
                      item_name: ProductDetail?.title,
                      item_brand: ProductDetail?.brand[0]?.title ?? "No Brand",
                      item_variant: SelectedVariant?.variant_id,
                      discount: handleDiscount(SelectedVariant),
                      quantity: ProductQuantity,
                      item_category: priorCategoryName,
                      price: removeCommasAndConvertToNumber(
                        SelectedVariant?.variant_detail?.discounted_price
                      ),
                      location_id: defaultgeoLocationCountry,
                    },
                  ],
                },
              });
            }

            // Moengage Add to cart

            let vendorTitle = null;
            if (ProductDetail?.brand?.length > 0) {
              vendorTitle = ProductDetail?.brand[0].title;
            }
            // Check if the images array has a length greater than zero
            let firstImage;
            if (ProductDetail?.images && ProductDetail?.images?.length > 0) {
              // Get the first image object
              firstImage = ProductDetail.images[0];
            }
            // console.log("ProductAdded", ProductAdded)
            if (window.Moengage && window.Moengage.track_event) {
              window.Moengage.track_event("add_to_cart", {
                "Event Received Time": currentDateTime,
                Currency: defaultCurrency ? defaultCurrency : "PKR",
                Source: "Website",
                Email: customerEmail[0] ? customerEmail[0] : null,
                "Product name": ProductDetail?.title,
                "Variation ID": SelectedVariant?.variant_id,
                "Product Handle": ProductDetail?.handle,
                Available: !ProductDetail?.sold_out,
                "Product ID": ProductDetail?.id,
                Price: SelectedVariant?.variant_detail?.discounted_price,
                "Product Brand Name": vendorTitle,
                Quantity: ProductQuantity,
                hash: ProductAdded?.cartid,
                "Product image": firstImage.cdn_link
                  ? firstImage.cdn_link
                  : null,
                // "First Session": true
              });
              if (ProductDetail?.pre_order) {
                window.Moengage.track_event("prebook_add_to_cart", {
                  "Event Received Time": currentDateTime,
                  Currency: defaultCurrency ? defaultCurrency : "PKR",
                  Source: "Website",
                  Email: customerEmail[0] ? customerEmail[0] : null,
                  "Product name": ProductDetail?.title,
                  "Variation ID": SelectedVariant?.variant_id,
                  "Product Handle": ProductDetail?.handle,
                  Available: !ProductDetail?.sold_out,
                  "Product ID": ProductDetail?.id,
                  Price: SelectedVariant?.variant_detail?.discounted_price,
                  "Product Brand Name": vendorTitle,
                  Quantity: ProductQuantity,
                  hash: ProductAdded?.cartid,
                  "Product image": firstImage.cdn_link
                    ? firstImage.cdn_link
                    : null,
                  // "First Session": true
                });
              }
            }
          }

          return ProductAdded;
        }
      } catch (error) {
        console.error("AddToCart Api Failed!", error.message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } else if (viewBagItemsState) {
      router.push(`/cart/checkout?id=${cartId}`);
    } else {
      setSelectSizeError(true);
    }
  };

  // User Interactions
  const comverseInsight = async () => {
    const uuid = localStorage.getItem("uuid");
    const url = `${process.env.NEXT_PUBLIC_BACKEND}/user_tracking/interactions`;
    let interactionType = "add_to_cart";
    const data = {
      user_uuid: uuid,
      interaction_type: interactionType,
      path: window.location.pathname,
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //? Facebook Pixel ------------------------------------------------------------->

  useEffect(() => {
    calculateAddonPrice();
  }, [calculateAddonPrice, LineItemAddons]);

  // useEffect(() => {
  //   console.log(pushedVariants);
  // }, [pushedVariants]);

  useEffect(() => {
    // console.log(SelectedVariant);
    // console.log(pushedVariants);
    if (isMobile) {
      const findPushedVariant = pushedVariants?.find(
        (item) => item["option1"] === SelectedVariant?.["option1"]
      );
      if (findPushedVariant) {
        setViewBagItemsState(true);
      } else {
        setViewBagItemsState(false);
      }
    }
  }, [SelectedVariant, pushedVariants, isMobile]);

  useEffect(() => {
    setViewBagItemsState(false);
  }, []);

  useEffect(() => {
    if (loggedIn && comverse_customer_token) {
      getWishlist();
    }
  }, [comverse_customer_token, getWishlist, loggedIn]);

  useEffect(() => {
    return () => {
      dispatch(displayCart(false));
      dispatch(displayWishlist(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (Cookies.get("user_agent")) {
      setuserAgentFromCookies(Cookies.get("user_agent"));
    } else {
      let sessionId =
        Date.now().toString() +
        Math.floor(Math.random() * 1000000000000).toString();
      Cookies.set("user_agent", sessionId);
      setuserAgentFromCookies(sessionId);
    }
  }, []);

  const handleProductDiscountedPrice = () => {
    // .toFixed(2)
    {
      return (
        removeCommasAndConvertToNumber(
          SelectedVariant?.variant_detail?.discounted_price
        ) + removeCommasAndConvertToNumber(TotalAddonPrice)
      ).toLocaleString();
    }
  };

  const handleProductOrignalPrice = () => {
    // .toFixed(2)
    {
      return (
        removeCommasAndConvertToNumber(
          SelectedVariant?.variant_detail?.original_price
        ) + removeCommasAndConvertToNumber(TotalAddonPrice)
      ).toLocaleString();
    }
  };

  const handlePercentageOff = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      SelectedVariant?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return ""; // Return an empty string or handle edge cases where the percentage cannot be calculated
  };

  const handleMainPricePercentageOff = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      availableVariants?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      availableVariants?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return ""; // Return an empty string or handle edge cases where the percentage cannot be calculated
  };
  return (
    <Col
      className={
        isMobile || isTablet
          ? "ProductDetailPanel"
          : isDesktop
            ? "ProductDetailPanel"
            : ""
      }
    >
      <Spin spinning={Loading} delay={500}>
        <Row className="width100" gutter={[0, 10]}>
          {/* //* Product Title ---------------------------------------------------------------> */}

          <Col span={24} flex="100%">
            <Space
              className={
                isMobile || isTablet
                  ? "width100 flexBetween"
                  : "width100 paddingRight20"
              }
            >
              <Text
                className={
                  isMobile || isTablet
                    ? "title font16 lineHeight22"
                    : "title font20 lineHeight30"
                }
              >
                <h1 className="product-title test">
                  {ProductDetail?.title ? ProductDetail?.title : "N/A"}{" "}
                </h1>
              </Text>

              {/* //* Mobile View Wishlist Button ---------------------------------------------> */}

              <div
                style={{
                  width: "42px",
                  height: "42px",
                  borderRadius: "25px",
                  background: "#ffffff",
                }}
                className={isMobile || isTablet ? "flexCenter" : "displayNone"}
              >
                {isMobile || isTablet ? (
                  ProductInWishList ? (
                    <HeartFilled
                      onClick={() => removeProductFromWishlist()}
                      style={{ fontSize: "24px", color: "#FF2424" }}
                    />
                  ) : (
                    <HeartOutlined
                      onClick={() => addProductToWishlist()}
                      style={{ fontSize: "24px", color: "#FF2424" }}
                    />
                  )
                ) : null}
              </div>
            </Space>
          </Col>

          {/* //* Product SKU, Brand, Fabric -------------------------------------------------> */}

          <Col
            className={
              isMobile || isTablet
                ? "backgroundLight paddingLeft10 paddingRight10"
                : ""
            }
            flex="100%"
          >
            <Row
              className={
                isMobile || isTablet
                  ? "width100 paddingTop10 paddingBottom10"
                  : "width100 paddingTop10 paddingBottom10 borderTop borderBottom"
              }
              gutter={16} // Adds spacing between columns
            >
              {/* SKU Column */}
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text
                      className={
                        isDesktop
                          ? "secondary font14 poppinsSemiBold font600"
                          : "font12 secondary font600"
                      }
                    >
                      SKU {isDesktop ? <span>:</span> : null}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text className="secondaryDark">
                      {SelectedVariant?.sku
                        ? SelectedVariant?.sku
                        : availableVariants?.sku
                          ? availableVariants?.sku
                          : "N/A"}
                    </Text>
                  </Col>
                </Row>
              </Col>

              {/* Brand Column */}
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text
                      className={
                        isDesktop
                          ? "secondary font14 poppinsSemiBold font600"
                          : "font12 secondary font600"
                      }
                    >
                      Brand {isDesktop ? <span>:</span> : null}
                    </Text>
                  </Col>
                  <Col span={24}>
                    {ProductDetail?.brand?.map((brand, key) => (
                      <Link href={`/${brand?.handle}`} key={key}>
                        <Text className="secondaryDark">
                          {brand?.title ? brand?.title : "N/A"}
                        </Text>
                      </Link>
                    ))}
                  </Col>
                </Row>
              </Col>

              {/* Shirt Fabric Column */}
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text
                      className={
                        isDesktop
                          ? "secondary font14 poppinsSemiBold font600"
                          : "font12 secondary font600"
                      }
                    >
                      Shirt Fabric {isDesktop ? <span>:</span> : null}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text className="secondaryDark">
                      {Fabric?.value ? Fabric?.value : "N/A"}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* //* Desktop View Product Price ---------------------------------------------------------------> */}

          <Col xs={{ span: 0 }} lg={{ span: 24 }}>
            {SelectedVariant ? (
              <Row>
                {removeCommasAndConvertToNumber(
                  SelectedVariant?.variant_detail?.discounted_price
                ) <=
                  removeCommasAndConvertToNumber(
                    SelectedVariant?.variant_detail?.original_price
                  ) ? (
                  <Row style={{ overflow: "hidden" }} gutter={[10, 0]}>
                    <Col span={24}>
                      <Text className="price main-price">
                        {defaultCurrency}
                        &nbsp;
                        {/* {handleProductPrice()} */}
                        {handleProductDiscountedPrice()}
                      </Text>
                    </Col>

                    {removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.discounted_price
                    ) <
                      removeCommasAndConvertToNumber(
                        SelectedVariant?.variant_detail?.original_price
                      ) ? (
                      <Space className="paddingLeft5">
                        <Text className="priceCut">
                          {defaultCurrency}
                          &nbsp;
                          {/* {SelectedVariant?.variant_detail?.original_price
                            ? parseFloat(
                              SelectedVariant?.variant_detail
                                ?.original_price + TotalAddonPrice
                            ).toFixed(2)
                            : "N/A"} */}
                          {/* {handleProductPrice()} */}
                          {handleProductOrignalPrice()}
                        </Text>
                        {/* <Tag
                          className={
                            !isDesktop
                              ? "font14 discountTag alignCenter borderNone borderRadius0"
                              : "discountTag alignCenter borderNone borderRadius0"
                          }
                        >
                          {handlePercentageOff()}
                        </Tag> */}
                      </Space>
                    ) : null}
                  </Row>
                ) : null}
                {ProductDetail?.loyalty_points > 0 && (
                  <Space className="marginLeft5">
                    <div
                      style={{
                        height: "20px",
                        display: "flex",
                        padding: "0 5px",
                        minWidth: "58px",
                        alignItems: "center",
                        background: "#2A1E5C",
                        borderRadius: "9px 0px 9px 9px",
                      }}
                    >
                      <Image
                        width={13}
                        height={15}
                        src={Points}
                        alt="LoyaltyPoints"
                      />
                      <span
                        className="font11Bold"
                        style={{ color: "white", paddingLeft: "5px" }}
                      >
                        {ProductDetail?.loyalty_points}
                      </span>
                    </div>
                  </Space>
                )}
              </Row>
            ) : (
              <Row>
                {removeCommasAndConvertToNumber(
                  availableVariants?.variant_detail?.discounted_price
                ) <=
                  removeCommasAndConvertToNumber(
                    availableVariants?.variant_detail?.original_price
                  ) ? (
                  <Row style={{ overflow: "hidden" }} gutter={[10, 0]}>
                    <Col span={24}>
                      <Text className="price main-price">
                        {defaultCurrency}
                        &nbsp;
                        {availableVariants?.variant_detail?.discounted_price}
                      </Text>
                    </Col>

                    {removeCommasAndConvertToNumber(
                      availableVariants?.variant_detail?.discounted_price
                    ) <
                      removeCommasAndConvertToNumber(
                        availableVariants?.variant_detail?.original_price
                      ) ? (
                      <Space className="paddingLeft5">
                        <Text className="priceCut">
                          {defaultCurrency}
                          &nbsp;
                          {availableVariants?.variant_detail?.original_price}
                        </Text>
                        <Tag
                          className={
                            !isDesktop
                              ? "font14 discountTag alignCenter borderNone borderRadius0"
                              : "discountTag alignCenter borderNone borderRadius0"
                          }
                        >
                          {handleMainPricePercentageOff()}
                        </Tag>
                      </Space>
                    ) : null}
                  </Row>
                ) : null}
                {ProductDetail?.loyalty_points > 0 && (
                  <Space className="marginLeft5">
                    <div
                      style={{
                        height: "20px",
                        display: "flex",
                        padding: "0 5px",
                        minWidth: "58px",
                        alignItems: "center",
                        background: "#2A1E5C",
                        borderRadius: "9px 0px 9px 9px",
                      }}
                    >
                      <Image
                        width={13}
                        height={15}
                        src={Points}
                        alt="LoyaltyPoints"
                      />
                      <span
                        className="font11Bold"
                        style={{ color: "white", paddingLeft: "5px" }}
                      >
                        {ProductDetail?.loyalty_points}
                      </span>
                    </div>
                  </Space>
                )}
              </Row>
            )}
          </Col>

          {/* //* Product Options Size, Color etc ---------------------------------------------> */}

          <Col flex="100%">
            {ProductDetail?.is_stitch ? (
              <StitchingModule
                handleProductDiscountedPrice={handleProductDiscountedPrice}
                handleProductOrignalPrice={handleProductOrignalPrice}
                userAgent={userAgent}
                ProductDetail={ProductDetail}
                SelectedVariant={SelectedVariant}
                EnableStitching={EnableStitching}
                setEnableStitching={setEnableStitching}
                DefaultStitching={DefaultStitching}
                setDefaultStitching={setDefaultStitching}
                LineItemAddons={LineItemAddons}
                setLineItemAddons={setLineItemAddons}
                setViewBagItemsState={setViewBagItemsState}
                pushedVariants={pushedVariants}
                setPushedVariants={setPushedVariants}
                availableVariants={availableVariants}
                ProductQuantity={ProductQuantity}
                setProductQuantity={setProductQuantity}
                setSelectedVariant={setSelectedVariant}
                productSoldout={productSoldout}
                setProductSoldout={setProductSoldout}
                selectedValueFromOptions={selectedValueFromOptions}
                setSelectedValueFromOptions={setSelectedValueFromOptions}
                handleOptionChange={handleOptionChange}
                viewBagItemsState={viewBagItemsState}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                showDrawer={showDrawer}
                addProduct={addProduct}
                addToCartLoader={addToCartLoader}
                setaddToCartLoader={setaddToCartLoader}
                isSizeChartOpen={isSizeChartOpen}
                setIsSizeChartOpen={setIsSizeChartOpen}
              />
            ) : (
              <ProductOptions
                handleProductDiscountedPrice={handleProductDiscountedPrice}
                handleProductOrignalPrice={handleProductOrignalPrice}
                availableVariants={availableVariants}
                ProductQuantity={ProductQuantity}
                setProductQuantity={setProductQuantity}
                userAgent={userAgent}
                ProductDetail={ProductDetail}
                SelectedVariant={SelectedVariant}
                setSelectedVariant={setSelectedVariant}
                productSoldout={productSoldout}
                setProductSoldout={setProductSoldout}
                LineItemAddons={LineItemAddons}
                setLineItemAddons={setLineItemAddons}
                selectedValueFromOptions={selectedValueFromOptions}
                setSelectedValueFromOptions={setSelectedValueFromOptions}
                handleOptionChange={handleOptionChange}
                viewBagItemsState={viewBagItemsState}
                setViewBagItemsState={setViewBagItemsState}
                pushedVariants={pushedVariants}
                setPushedVariants={setPushedVariants}
                selectSizeError={selectSizeError}
                setSelectSizeError={setSelectSizeError}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                showDrawer={showDrawer}
                addProduct={addProduct}
                addToCartLoader={addToCartLoader}
                setaddToCartLoader={setaddToCartLoader}
                isSizeChartOpen={isSizeChartOpen}
                setIsSizeChartOpen={setIsSizeChartOpen}
              />
            )}
          </Col>

          <Col flex="100%">
            <Row
              align="middle"
              gutter={[0, 16]}
              style={{
                width: "100%",
                height: "auto",
                overflow: "hidden",
                justifyContent: "space-between",
                // height: isMobile || isTablet ? "70px" : "auto",
              }}
              className={
                isMobile || isTablet ? "backgroundLight borderRadius5 " : ""
              }
            >
              {isMobile || isTablet ? (
                SelectedVariant ? (
                  <Col flex="0 0 auto">
                    {removeCommasAndConvertToNumber(
                      SelectedVariant?.variant_detail?.discounted_price
                    ) <=
                      removeCommasAndConvertToNumber(
                        SelectedVariant?.variant_detail?.original_price
                      ) ? (
                      <Row
                        gutter={[10, 0]}
                        className="flexColumn"
                        style={{ overflow: "hidden" }}
                      >
                        <Col flex="0 0 auto">
                          <Text className="font16Bold price">
                            {defaultCurrency}
                            &nbsp;
                            {handleProductDiscountedPrice()}
                          </Text>
                        </Col>

                        {removeCommasAndConvertToNumber(
                          SelectedVariant?.variant_detail?.discounted_price
                        ) <
                          removeCommasAndConvertToNumber(
                            SelectedVariant?.variant_detail?.original_price
                          ) ? (
                          <Space className="paddingLeft5">
                            <Text className="font16 priceCut">
                              {defaultCurrency}
                              &nbsp;
                              {/* {SelectedVariant?.variant_detail?.original_price
                                ? parseFloat(
                                  SelectedVariant?.variant_detail
                                    ?.original_price + TotalAddonPrice
                                ).toFixed(2)
                                : "N/A"} */}
                              {handleProductOrignalPrice()}
                            </Text>
                            {/* <Tag className="discountTag alignCenter borderNone borderRadius0 font14">
                              
                              {handlePercentageOff()}
                            </Tag> */}
                          </Space>
                        ) : null}
                      </Row>
                    ) : null}
                  </Col>
                ) : (
                  <Col flex="0 0 auto">
                    {removeCommasAndConvertToNumber(
                      availableVariants?.variant_detail?.discounted_price
                    ) <=
                      removeCommasAndConvertToNumber(
                        availableVariants?.variant_detail?.original_price
                      ) ? (
                      <Row
                        gutter={[10, 0]}
                        className="flexColumn"
                        style={{ overflow: "hidden" }}
                      >
                        <Col flex="0 0 auto">
                          <Text className="font16Bold price">
                            {defaultCurrency}
                            &nbsp;
                            {
                              availableVariants?.variant_detail
                                ?.discounted_price
                            }
                          </Text>
                        </Col>

                        {removeCommasAndConvertToNumber(
                          availableVariants?.variant_detail?.discounted_price
                        ) <
                          removeCommasAndConvertToNumber(
                            availableVariants?.variant_detail?.original_price
                          ) ? (
                          <Space className="paddingLeft5">
                            <Text className="font16 priceCut">
                              {defaultCurrency}
                              &nbsp;
                              {
                                availableVariants?.variant_detail
                                  ?.original_price
                              }
                            </Text>
                            {/* <Tag className="discountTag alignCenter borderNone borderRadius0 font14">
                              {handleMainPricePercentageOff()}
                            </Tag> */}
                          </Space>
                        ) : null}
                      </Row>
                    ) : null}
                  </Col>
                )
              ) : null}
              {isMobile || isTablet ? (
                <Col>
                  {ProductDetail?.loyalty_points > 0 && (
                    <Space className="marginLeft5">
                      <div
                        style={{
                          height: "20px",
                          display: "flex",
                          padding: "0 5px",
                          minWidth: "58px",
                          alignItems: "center",
                          background: "#2A1E5C",
                          borderRadius: "9px 0px 9px 9px",
                        }}
                      >
                        <Image
                          width={13}
                          height={15}
                          src={Points}
                          alt="LoyaltyPoints"
                        />
                        <span
                          className="font11Bold"
                          style={{ color: "white", paddingLeft: "5px" }}
                        >
                          {ProductDetail?.loyalty_points}
                        </span>
                      </div>
                    </Space>
                  )}
                </Col>
              ) : null}
              <Col
                flex="1 1 auto"
                className={isMobile || isTablet ? "flexEnd" : ""}
              >
                <Row justify="space-between" align="middle" gutter={[0, 10]}>
                  <Col>
                    <Space
                      className={
                        isMobile || isTablet
                          ? "width100 flexEnd"
                          : "width100 flexEnd"
                      }
                    >
                      <Text
                        className={isMobile || isTablet ? "displayNone" : ""}
                        style={{ display: "block", minWidth: "max-content" }}
                      >
                        Qty
                      </Text>

                      <InputNumber
                        className={
                          isMobile || isTablet
                            ? "MobileQuantityPicker"
                            : "QuantityPicker"
                        }
                        type="number"
                        readOnly={true}
                        controls={false}
                        value={ProductQuantity}
                        placeholder={ProductQuantity}
                        defaultValue={ProductQuantity}
                        addonBefore={
                          <Text
                            className="cursorPointer"
                            onClick={() => quantityDecrement()}
                          >
                            -
                          </Text>
                        }
                        addonAfter={
                          <Text
                            className="cursorPointer"
                            onClick={() => quantityIncrement()}
                          >
                            +
                          </Text>
                        }
                      />
                    </Space>
                  </Col>

                  {isMobile || isTablet ? null : (
                    <Col flex="1 1 auto" className="flexCenter">
                      <Divider type="vertical" className="margin0" />
                    </Col>
                  )}

                  {/* {isMobile || isTablet ? null : (
                      <Col flex="0 0 auto" className="flexCenter">
                        {ProductInWishList ? (
                          <DynamicButton
                            type="primary"
                            property="danger"
                            className="wishList_btn boxShadowNone padding10"
                            name="Remove from Wishlist"
                            function={removeProductFromWishlist}
                            icon={<HeartOutlined color="red" />}
                            style={{
                              fontSize: "12px",
                              fontFamily: "ZemestroStd-Regular",
                              fontWeight: 100,
                            }}
                          />
                        ) : (
                          <DynamicButton
                            type="primary"
                            name="Add to Wishlist"
                            className="wishList_btn boxShadowNone"
                            function={addProductToWishlist}
                            icon={<HeartOutlined color="red" />}
                            style={{
                              fontSize: "12px",
                              fontFamily: "ZemestroStd-Regular",
                              fontWeight: 100,
                            }}
                          />
                        )}
                      </Col>
                    )} */}

                  {isMobile || isTablet ? null : (
                    <Col flex="1 1 auto" className="flexCenter">
                      <Divider type="vertical" className="margin0" />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>

          {/* //* Desktop View Buttons Sections -----------------------------------------------> */}

          {/* {isDesktop ? ( */}
          <Col flex="100%">
            <Row
              className={
                (isMobile && !ProductDetail?.pre_order) ||
                  (isTablet && !ProductDetail?.pre_order)
                  ? "width100 flexBetween"
                  : (isMobile && ProductDetail?.pre_order) ||
                    (isTablet && ProductDetail?.pre_order)
                    ? "width100 flexBetween"
                    : isDesktop && !ProductDetail?.pre_order
                      ? "width100 flexBetween   overflowy-auto"
                      : isDesktop && ProductDetail?.pre_order
                        ? "width100 flexBetween   overflowy-auto"
                        : ""
              }
            >
              {ProductDetail?.sold_out === false ? (
                // !ProductDetail?.pre_order && (
                !productSoldout ? (
                  <Col flex="33%" className="flexCenter">
                    {ProductInWishList ? (
                      <DynamicButton
                        type="primary"
                        property="danger"
                        className="primary_btn_outline"
                        name="Remove from Wishlist"
                        function={removeProductFromWishlist}
                        icon={
                          <Image
                            height={15}
                            width={15}
                            src="/heart-icon-1.svg"
                            style={{
                              marginRight: "-7px",
                              marginBottom: "1px",
                            }}
                          />
                        }
                        style={{
                          fontSize: "12px",
                          fontFamily: "ZemestroStd-Regular",
                          fontWeight: 100,
                          paddingTop: "18px",
                          paddingBottom: "18px",
                        }}
                      />
                    ) : (
                      <DynamicButton
                        type="primary"
                        name="Add to Wishlist"
                        className="primary_btn_outline"
                        function={addProductToWishlist}
                        icon={
                          <Image
                            height={16}
                            width={16}
                            src="/heart-icon-1.svg"
                            style={{
                              marginRight: "-7px",
                              marginBottom: "2px",
                            }}
                          />
                        }
                        style={{
                          fontSize: "12px",
                          fontFamily: "ZemestroStd-Regular",
                          fontWeight: 100,
                          paddingTop: isMobile ? "12px" : "21px",
                          paddingBottom: isMobile ? "10px" : "21px",
                        }}
                      />
                    )}
                  </Col>
                ) : (
                  <Col flex="33%" onClick={() => setShowNotifyModal(true)}>
                    <DynamicButton
                      type="primary"
                      name="Notify on Restock"
                      className="primary_btn_outline"
                      style={{
                        height: "40px",
                        fontSize: "16px",
                        borderRadius: "3px",
                        textTransform: "capitalize",
                      }}
                    />
                  </Col>
                )
              ) : (
                <Col flex="33%" onClick={() => setShowNotifyModal(true)}>
                  <DynamicButton
                    type="primary"
                    name="Notify on Restock"
                    className="primary_btn_outline"
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      borderRadius: "3px",
                      textTransform: "capitalize",
                    }}
                  />
                </Col>
              )}
              {ProductDetail?.sold_out === false ? (
                !productSoldout ? (
                  !selectSizeError ? (
                    <Col
                      flex={isDesktop ? "55%" : ""}
                      style={{ marginLeft: "10px" }}
                    >
                      <DynamicButton
                        type="primary"
                        name={
                          addToCartLoader
                            ? "Adding..."
                            : viewBagItemsState
                              ? "View Cart"
                              : ProductDetail?.pre_order
                                ? "Pre Book"
                                : "Add to Bag"
                        }
                        function={addProduct}
                        // function={addProductToCart}
                        className="primary_btn poppinsMedium"
                        icon={
                          !addToCartLoader ? (
                            <Image
                              height={20}
                              width={20}
                              src="/Handbag-1.svg"
                              style={{
                                marginRight: "-7px",
                                marginBottom: "4px",
                              }}
                            />
                          ) : (
                            <Spin
                              indicator={
                                <RedoOutlined
                                  spin
                                  className="spinner-redo-white"
                                  fontSize={"20px"}
                                  style={{
                                    color: "white",
                                  }}
                                />
                              }
                            />
                          )
                        }
                        style={{
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "ZemestroStd-Medium",
                          padding: "22px",
                          backgroundColor: !addToCartLoader
                            ? viewBagItemsState
                              ? "black"
                              : "#FF0D50"
                            : "#FF0D50",
                        }}
                      />
                    </Col>
                  ) : (
                    <Col
                      flex={isDesktop ? "55%" : ""}
                      style={{ marginLeft: "10px" }}
                    >
                      <DynamicButton
                        type="primary"
                        name="Select Size "
                        // function={addProduct}
                        // function={addProductToCart}
                        className="primary_btn poppinsMedium"
                        icon={
                          <Image
                            height={23}
                            width={23}
                            src="/inch-1.svg"
                            style={{
                              marginRight: "-7px",
                              marginBottom: "2px",
                              color: "white",
                            }}
                          />
                        }
                        style={{
                          height: "40px",
                          fontSize: "16px",
                          fontFamily: "ZemestroStd-Medium",
                          backgroundColor: !addToCartLoader
                            ? viewBagItemsState
                              ? "black"
                              : "#FF0D50"
                            : "#FF0D50",
                        }}
                      />
                    </Col>
                  )
                ) : (
                  <Col
                    flex={isDesktop ? "55%" : ""}
                    style={{ marginLeft: "10px" }}
                  >
                    <DynamicButton
                      type="primary"
                      name="Sold Out"
                      disabled={true}
                      property="danger"
                      className="primary_btn poppinsMedium"
                      icon={<HiOutlineShoppingBag color="white" />}
                      style={{
                        height: "40px",
                        fontSize: "16px",
                        fontFamily: "ZemestroStd-Medium",
                      }}
                    />
                  </Col>
                )
              ) : (
                <Col
                  flex={isDesktop ? "55%" : ""}
                  style={{ marginLeft: "10px" }}
                >
                  <DynamicButton
                    type="primary"
                    name="Sold Out"
                    disabled={true}
                    property="danger"
                    className="primary_btn poppinsMedium"
                    icon={<HiOutlineShoppingBag color="white" />}
                    style={{
                      height: "40px",
                      fontSize: "16px",
                      fontFamily: "ZemestroStd-Medium",
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
          {/* ) : null} */}

          <Modal
            footer={null}
            // open={true}
            open={ShowNotifyModal}
            title="Notify on Restock"
            // className="NotifyModal"
            wrapClassName="NotifyModal"
            onCancel={showNotificationModal}
          >
            <Col span={24}>
              <Form
                size="large"
                layout="vertical"
                name="notify_form"
                onFinish={notifyUser}
                className="notify_form"
                initialValues={{
                  remember: true,
                }}
                style={{
                  width: "100%",
                }}
              >
                <Col>
                  <Text className="font16">Name</Text>
                  <Form.Item
                    // label="Name"
                    name="full_name"
                    style={{ margin: "10px 0px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid name!",
                      },
                      // { min: 3, message: "Too Short!" },
                      { max: 40, message: "Too Long!" },
                      {
                        pattern: /^[aA-zZ\s]+$/,
                        message: "Only alphabets are allowed for this field ",
                      },
                    ]}
                  >
                    <Input placeholder="Full Name" />
                  </Form.Item>

                  <Text className="font16">Email</Text>
                  <Form.Item
                    // label="Email"

                    name="email"
                    style={{ margin: "10px 0px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid email!",
                      },
                      {
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="John@example.com" />
                  </Form.Item>

                  <Text className="font16">Mobile</Text>
                  <Form.Item
                    // label="Mobile"
                    name="phoneNo"
                    className="marginTop10"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <PhoneInput country={"pk"} />
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    style={{
                      height: "40px",
                      fontWeight: 600,
                      fontSize: "16px",
                      // minWidth: "422px",
                      marginTop: "20px",
                      borderRadius: "6px",
                      background: "black",
                    }}
                  >
                    Notify on Restock
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Modal>
          {/* 
          <Col
            flex={
              isMobile || isTablet
                ? "100%"
                : ProductDetail?.images?.length === 1
                ? "60%"
                : "96%"
            }
          >
            <ProductDescription
              Color={Color}
              Fabric={Fabric}
              userAgent={userAgent}
              ProductDetail={ProductDetail}
            />
          </Col> */}
        </Row>
      </Spin>
      <LoginPage
        ShowLoginModal={ShowLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
    </Col>
  );
}
