"use client";
//? Library Imports ------------------------------------------------------------>

import { Col, Row } from "antd";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Image from "next/image";
import { ZoomInOutlined } from "@ant-design/icons";
import "./ImageGallery.scss";

export default function ImageGallery({ ProductDetail }) {
  return (
    <Col>
      {ProductDetail?.images?.length ? (
        <Row>
          <Col span={24} className="flex width100 marginBottom10">
            <div
              className="enlargeButton"
              style={{
                position: "absolute",
                cursor: "pointer",
                left: "10px",
                top: "10px",
                zIndex: 1,
              }}
              onClick={() =>
                NativeFancybox.show(
                  ProductDetail?.images?.map((img) => ({
                    src: img?.cdn_link,
                    type: "image",
                  })),
                  {
                    startIndex: 0,
                  }
                )
              }
            >
              <ZoomInOutlined className="iconStyle" />
              <span className="hoverText">Click to enlarge</span>
            </div>
            <Image
              width={500}
              height={500}
              alt={ProductDetail?.images[0]?.alt || "Default Image"}
              src={ProductDetail?.images[0]?.cdn_link}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
            }}
            className={
              ProductDetail?.images?.length > 1 ? "flexBetween" : "gap10"
            }
          >
            {ProductDetail?.images?.length > 1 &&
              ProductDetail?.images?.map((image, index) => {
                return (
                  index < 3 && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        NativeFancybox.show(
                          ProductDetail?.images?.map((img) => ({
                            src: img?.cdn_link,
                            type: "image",
                          })),
                          {
                            startIndex: index,
                          }
                        )
                      }
                    >
                      <Image
                        width={50}
                        height={100}
                        key={index}
                        alt={image?.alt || "Default Image"}
                        src={image?.cdn_link}
                        style={{
                          width: "auto",
                          height: "90%",
                        }}
                      />
                    </div>
                  )
                );
              })}
          </Col>
        </Row>
      ) : null}
    </Col>
  );
}
