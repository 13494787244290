//? Library Imports ------------------------------------------------------------>
import { useState, useEffect } from "react";
import { Col, Row, Space, Button, Modal, Typography } from "antd";
import { signIn, useSession, signOut } from "next-auth/react";

//? Assets --------------------------------------------------------------------->
import "./LoginPage.scss";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";

//? Modules -------------------------------------------------------------------->
import LoginForm from "./Modules/LoginForm";
import SignupForm from "./Modules/SignupForm";
import LoginSignup from "@/components/SignUpPage/LoginSignup";

//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function LoginPage({ ShowLoginModal, setShowLoginModal }) {
  // const [ShowModal, setShowModal] = useState(false);
  const [ModalType, setModalType] = useState("login");

  useEffect(() => {
    return () => { };
  }, [ShowLoginModal]);

  return (
    <Modal
      centered
      wrapClassName="login-modal login-modal-outer-container"
      // width={533}
      footer={false}
      open={ShowLoginModal}
      // bodyStyle={{
      //   padding: '0px',
      //   borderRadius: '35px'
      // }}
      // className="login-modal"
      onOk={() => {
        setShowLoginModal(false);
        setModalType("login");
      }}
      onCancel={() => {
        setShowLoginModal(false);
        setModalType("login");
      }}
    >
      <LoginSignup setShowLoginModal={setShowLoginModal} />
    </Modal>
  );
}
