"use client";
import { useState, useEffect, useMemo, useRef } from "react";
import Slider from "react-slick";
import { Col, Image, Row } from "antd";
import { ZoomInOutlined } from "@ant-design/icons";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

//? Assets --------------------------------------------------------------------->
import "./ImagesCarousel.scss";
import DefaultImage from "../../../../../assets/images/defaultImages/DefaultProductImage.jpg";

export default function ImagesCarousel({ Images, ProductDetail }) {
  const [imageLoaded, setImageLoaded] = useState({});
  const [imageSrcs, setImageSrcs] = useState({});
  const [mainSlider, setMainSlider] = useState(null);
  const [thumbnailSlider, setThumbnailSlider] = useState(null);

  const NoOfSlides = useMemo(() => Images?.length, [Images]);

  const onImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };

  useEffect(() => {
    const initialSrcs = Images?.reduce(
      (acc, img, index) => ({
        ...acc,
        [index]: img?.cdn_link ? img.cdn_link + "%3Fwidth%3D640" : DefaultImage,
      }),
      {}
    );
    setImageSrcs(initialSrcs);
  }, [Images]);

  const handleImageError = (index, originalSrc) => {
    setImageSrcs((prevSrcs) => ({
      ...prevSrcs,
      [index]: originalSrc || DefaultImage,
    }));
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    arrows: false,
    asNavFor: thumbnailSlider, // Sync with the thumbnail slider
    fade: true,
  };

  const thumbnailSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: mainSlider, // Sync with the main slider
    focusOnSelect: true,
    arrows: true,
    infinite: false,
  };

  return (
    <Col span={24} className="carousel_container ImagesCarousel">
      <Slider {...settings} ref={(slider) => setMainSlider(slider)}>
        {Images?.map((slide, index) => (
          <div key={index}>
            <div style={{ position: "relative" }}>
              {!imageLoaded[index] && (
                <div
                  style={{
                    width: "100%",
                    height: "470px",
                    backgroundColor: "white",
                    position: "relative",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
              )}
              <div
                className="enlargeButton"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "10px",
                  bottom: "10px",
                  zIndex: 1,
                }}
                onClick={() =>
                  NativeFancybox.show(
                    Images?.map((img) => ({
                      src: img?.cdn_link,
                      type: "image",
                    })),
                    {
                      startIndex: index,
                    }
                  )
                }
              >
                <ZoomInOutlined className="iconStyle" />
                <span className="hoverText">Click to enlarge</span>
              </div>
              <Image
                width="100%"
                height="auto"
                preview={false}
                onLoad={() => onImageLoad(index)}
                className="borderRadius5"
                src={imageSrcs[index]}
                onError={() => handleImageError(index, slide?.cdn_link)}
                alt={ProductDetail?.title || "Default Image"}
                title={ProductDetail?.title || "Default Image"}
                style={{
                  objectFit: "cover",
                  opacity: imageLoaded[index] ? 1 : 0,
                  zIndex: 2,
                  display: !imageLoaded[index] ? "none" : "block",
                }}
              />
            </div>
          </div>
        ))}
      </Slider>

      {/* Thumbnail Slider */}
      <div className="thumbnail-slider">
        <Slider
          {...thumbnailSettings}
          ref={(slider) => setThumbnailSlider(slider)}
        >
          {Images?.map((slide, index) => (
            <div key={index} style={{ padding: "0 5px", cursor: "pointer" }}>
              <Image
                width={70}
                height={70}
                preview={false}
                className="thumbnailImage"
                src={imageSrcs[index]}
                onError={() => handleImageError(index, slide?.cdn_link)}
                alt={`Thumbnail ${index}`}
                style={{
                  borderRadius: "5px",
                  opacity: 1,
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Col>
  );
}
