"use client";

import { useEffect } from "react";
import { useRouter } from "next/navigation";

const useBrowserBackButton = () => {
  const router = useRouter();

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      router.back();
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [router]);
};

export default useBrowserBackButton;
