import { createSlice } from "@reduxjs/toolkit";

export const currencySlice = createSlice({
  name: "multiLocation",
  initialState: {
    countriesList: null,
    defaultCountryId: null,
    defaultCountry: null,
    defaultCurrency: null,
    defaultCountryCode: null,
  },
  reducers: {
    setCountriesList: (state, action) => {
      state.countriesList = action.payload;
    },

    setDefaultCountry: (state, action) => {
      state.defaultCountryId = action.payload?.id;
      state.defaultCountry = action.payload?.country_name;
      state.defaultCountryCode = action.payload?.short_code;
      state.defaultCurrency = action.payload?.currency;
    },

    changeCountry: (state, action) => {
      state.defaultCountry = action.payload;
      // localStorage.removeItem("cart");
      // localStorage.removeItem("wishList");

      let cart = [];
      let wishList = [];
      // localStorage.setItem("cart", JSON.stringify(cart));
      // localStorage.setItem("wishList", JSON.stringify(wishList));
    },
    changeCountryId: (state, action) => {
      state.defaultCountryId = action.payload;
    },
    changeCurrency: (state, action) => {
      state.defaultCurrency = action.payload;
    },

    changeCountryCode: (state, action) => {
      state.defaultCountryCode = action.payload;
    },
  },
});

export const {
  setDefaultCountry,
  changeCountryCode,
  changeCountry,
  changeCurrency,
  setCountriesList,
  changeCountryId,
} = currencySlice.actions;
export default currencySlice.reducer;
