import { createSlice } from "@reduxjs/toolkit";

export const wishListSlice = createSlice({
  name: "wishListSlice",
  initialState: {
    wishList: [],
    showWishlist: false,
  },
  reducers: {
    addToWishList: (state, action) => {
      state.wishList = action.payload;
      localStorage.setItem("wishList", JSON.stringify(state.wishList));
    },
    removeFromWishList: (state, action) => {
      state.wishList = action.payload;
      localStorage.setItem("wishList", JSON.stringify(state.wishList));
    },
    updateWishList: (state, action) => {
      state.wishList = action.payload;
      localStorage.setItem("wishList", JSON.stringify(state.wishList));
    },
    clearWishList: (state) => {
      state.wishList = [];
      localStorage.setItem("wishList", JSON.stringify(state.wishList));
    },
    displayWishlist: (state, action) => {
      state.showWishlist = action.payload;
    },
  },
});

export const {
  updateWishList,
  addToWishList,
  removeFromWishList,
  clearWishList,
  displayWishlist,
} = wishListSlice.actions;
export default wishListSlice.reducer;
